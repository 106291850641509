import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {
  title="Contacto";
  constructor() { }

  ngOnInit(): void {
    setTimeout(function(){document.getElementById("btnTop").click();},0);
  }

}
