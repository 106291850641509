<app-subtitle [titulo]="title" [imagen]="'/assets/img/image-banner-problematica.jpg'"></app-subtitle>
<div class="feature free-text no-padding">
    <div class="bt col-md-5 float-left description">
        <div>
          <h1>{{definicion?.nombre_problema}}</h1>
          <img class="img-thumbnail" src="{{getUrlApiImagenes() + definicion?.url?.replace('/public/', '/')}}" class="card-img rounded" alt="Sin foto" height="200px" width="200px">
          <br>
          <br>
          <p [innerHTML]="replaceAll(definicion?.descripcion)" class="{{vermas ? 'open':''}}  text-justificado"></p>
          <button mat-flat-button color="primary" *ngIf="!vermas" (click)="vermas = true">Ver más</button>
          <button mat-flat-button color="primary" *ngIf="vermas" (click)="vermas = false">Ver menos</button>
      </div>
  </div>
  <div *ngIf="!spinner_inicial" else spinner_block>
    <div class="bt col-md-7 results float-left">
        <mat-toolbar color="default">QUIENES TE PUEDEN AYUDAR</mat-toolbar>
        <mat-icon>arrow_drop_down</mat-icon>
        <div *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="rows==0" class="text-align-center">
            “Lo sentimos, por el momento no tenemos
            registrada a alguna organización que
            atienda esta problemática en la localidad”
            <br>
            <img src="assets/img/FreshFolk-Teamwork.png" alt="fres" />
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" *ngIf="!isLoading && rows >0">
                <caption></caption>
                    <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                        <!-- Position Column -->
                        <ng-container matColumnDef="organizacion">
                            <th id="ogranizacion" mat-header-cell *matHeaderCellDef> Organización </th>
                            <td mat-cell *matCellDef="let element">
                                <img src="{{element.organizacion}}" height="50px" alt="Logo no disponible" />
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="tipo_organizacion">
                            <th id="tipo_organizacion" mat-header-cell *matHeaderCellDef> Tipo organizacion </th>
                            <td mat-cell *matCellDef="let element"> {{element.tipo_organizacion}} </td>
                        </ng-container>



                        <!-- Name Column -->
                        <ng-container matColumnDef="nombre">
                            <th id="nombre" mat-header-cell *matHeaderCellDef> Nombre </th>
                            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="tipo">
                            <th id="tipo" mat-header-cell *matHeaderCellDef> Tipo </th>
                            <td mat-cell *matCellDef="let element"> {{element.tipo}} </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="ciudad">
                            <th id="municipio"  mat-header-cell *matHeaderCellDef> Municipio </th>
                            <td mat-cell *matCellDef="let element"> {{element.ciudad}} </td>
                        </ng-container>

                        <ng-container matColumnDef="informacion">
                            <th id="informacion" mat-header-cell *matHeaderCellDef> Información </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button class="flat" color="primary" [routerLink]="['/osc/ficha/',element.tipo_organizacion,element.id]">VER
                                +</button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>


            </div>
        </div>
        <ng-template #spinne_block>
            <mat-spinner></mat-spinner>
        </ng-template>



    </div>