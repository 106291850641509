import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './structure/header/header.component';
import { AppMaterialModule } from './app-material/app-material.module';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './structure/footer/footer.component';
import { QuienesSomosComponent } from './pages/quienes-somos/quienes-somos.component';
import { AfiliacionComponent } from './pages/afiliacion/afiliacion.component';
import { PreguntasFrecuentesComponent } from './pages/preguntas-frecuentes/preguntas-frecuentes.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { TerminosCondicionesComponent } from './pages/terminos-condiciones/terminos-condiciones.component';
import { AvisoPrivacidadComponent } from './pages/aviso-privacidad/aviso-privacidad.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SubtitleComponent } from './componentes/subtitle/subtitle.component';
import { ProcesoDeAfiliacionComponent } from './pages/afiliacion/proceso-de-afiliacion/proceso-de-afiliacion.component';
import { ResultadosBusquedaComponent } from './pages/resultados-busqueda/resultados-busqueda.component';
import { MysqlService } from './services/mysql.service';
import { HttpClientModule } from '@angular/common/http';
import { SponsorsComponent } from './structure/sponsors/sponsors.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageBoxComponent } from './pages/message-box/message-box.component';
import { FichaOscComponent } from './pages/ficha-osc/ficha-osc.component';
import { LoaderComponent } from './componentes/loader/loader.component';
import { ErrorServidorComponent } from './alertas/error-servidor/error-servidor.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    QuienesSomosComponent,
    AfiliacionComponent,
    PreguntasFrecuentesComponent,
    ContactoComponent,
    TerminosCondicionesComponent,
    AvisoPrivacidadComponent,
    PageNotFoundComponent,
    SubtitleComponent,
    ProcesoDeAfiliacionComponent,
    ResultadosBusquedaComponent,
    SponsorsComponent,
    MessageBoxComponent,
    FichaOscComponent,
    LoaderComponent,
    ErrorServidorComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [MysqlService],
  bootstrap: [AppComponent]
})
export class AppModule { }
