<app-subtitle [titulo]="title" [imagen]="'/assets/img/imagen-otros-links.jpg'"></app-subtitle>
<div class="feature free-text">
    <mat-list>
        <h1> COMO AFILIARME SI SON ORGANIZACIÓN</h1>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Da clic en el botón que dice “Afíliate”</div>
        </mat-list-item>

        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>A continuación, se abrirá la plataforma “SOCIAL Y
                DIGITAL” donde deberás registrarte con tu
                usuario y contraseña con los datos de tu organización.
                Nota: Si eres una organización con fines de lucro, tu afiliación
                a Social y Digital tendrá un costo anual.
                Para mas información revisar el apartado de términos y
                condiciones</div>
        </mat-list-item>

        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Una vez que te has dado de alta, en el módulo de busco
                ayuda, deberás configurar las
                problemáticas que tu organización atienda.</div>
        </mat-list-item>

        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Para cualquier duda o solicitud de asistencia,
                comunícate con nosotros al siguiente correo;
                <a href="mailto:contacto@fundacionenter.org">contacto@fundacionenter.org </a>
               </div>
        </mat-list-item>
    </mat-list>
    <!--<mat-list>
     <h1>Afiliaciones Organizaciones de la Sociedad Civil</h1>
        <mat-list-item>
          <mat-icon mat-list-icon>check</mat-icon>
          <div mat-line>Ser una organización sin fines de lucro y estar legalmente constituida (CLUNI).</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Tener por lo menos seis meses operando los servicios que oferta.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Contar con un domicilio fiscal y operativo.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Proveer servicios de atención social, gratuitos o con cuota de recuperación.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>No pertenecer a ningún partido político.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>No realizar acciones proselitistas.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>No discriminar por sexo, raza, edad, nivel socioeconómico, procedencia, creencias, etc.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Aceptar los términos y condiciones de afiliación.</div>
        </mat-list-item>
    </mat-list>
    <br>
    <br>
    <br>
    <h1>Afiliaciones Organizaciones Privadas</h1>
    <mat-list>
        <mat-list-item>
          <mat-icon mat-list-icon>check</mat-icon>
          <div mat-line>Cubrir una cuota anual de afiliación (ver términos y condiciones).</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Ser una organización legalmente constituida (CLUNI).</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Tener al menos seis meses operando los servicios que oferta.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Contar con un domicilio fiscal y operativo.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Proveer servicios de atención social en las ciudades donde este registrada.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>No pertenecer a ningún partido político.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>No realizar acciones proselitistas.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>No discriminar por sexo, raza, edad, nivel socioeconómico, procedencia, creencias, etc.</div>
        </mat-list-item>
        <mat-list-item>
            <mat-icon mat-list-icon>check</mat-icon>
            <div mat-line>Aceptar los términos y condiciones de afiliación.</div>
        </mat-list-item>
    </mat-list>
-->
    <br>
    <br>
    <div style="text-align: center;">
        <a mat-raised-button color="primary" class="flat"
            href="{{getUrlsocialydigital()}}" target="_blank" rel="noopener">
            Afiliate
        </a>
    </div>
</div>