import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Generica } from '../interfaces/generica';
import { Generico } from '../interfaces/generico';

@Injectable({
  providedIn: 'root'
})
export class MysqlService {

  constructor(
    private _http: HttpClient) {
  }

  getUrlsocialydigital(){
    return environment.url_socialydigital;
  }

  getUrlApiImagenes(){
    return environment.apiImagenes;
  }

  getEstados(): Observable<Generica> {
    return this._http.get<Generica>(environment.apiUrl + "EstadosMexico");
  }
  getMunicipio(datos): Observable<Generica> {
    return this._http.post<Generica>(environment.apiUrl + 'MunicipiosEstado', datos);
  }
  getProblematicas(): Observable<Generica> {
    return this._http.get<Generica>(environment.apiUrl + "GrupoProblemasAtiende");

  }
  getOrganizaicion(id):Observable<Generico>{
    return this._http.post<Generico>(environment.apiUrl+"informacionInstitucion", {'institucion': id } );
  }

  getSucursal(id):Observable<Generico>{
    return this._http.post<Generico>(environment.apiUrl+"ConsultarSucursalBuscoAyuda", {'sucursal': id } );
  }

  setOsc(json: any): Observable<any> {
    console.info(json);
    return this._http.post("/php/cargaOrganizaciones.php", JSON.stringify(json));
  }

  getProblematica(problematica: Number): Observable<Generico> {
    return this._http.get<Generico>(environment.apiUrl + "ConsultarProblematica?problematica=" + problematica);
  }
  getInsitucionesProblematicas(problematica: Number, estado: number, municipio: number):Observable<Generica> {
    return this._http.get<Generica>(environment.apiUrl+"buscarProblematica?problematica="+problematica+"&estado="+estado+"&municipio="+municipio);

  }
  getOscs(problematica: String, estado: Number): Observable<any> {
    return this._http.get("/php/buscador.php?problematica=" + problematica + "&estado=" + estado);
  }
  getOsc(id: String): Observable<any> {
    return this._http.get("/php/getOsc.php?id=" + id);
  }

  getCatalogoProblematicas(): Observable<any> {
    return this._http.get("/php/getProblematicas.php")
  }

  cargarLogo(formData: FormData): Observable<any> {
    return this._http.post("/php/subirImg.php", formData);
  }


}
