<app-subtitle [titulo]="title" [imagen]="'/assets/img/image-banner-problematica.jpg'"></app-subtitle>
<div class="feature free-text">
    <p>Tienes alguna duda, comentario o sugerencia? Escríbenos junto con tu información de contacto a la siguiente dirección de correo electrónico <a href="mailto:contacto@buscoayuda.org">contacto@buscoayuda.org</a> y lo mas pronto posible nos pondremos en contacto contigo. </p>

    <mat-list>
        <div>Teléfono</div>
        <mat-list-item>
          <mat-icon mat-list-icon>phone</mat-icon>
          <div mat-line>(442) 2482304</div>
          
        </mat-list-item>
        <div>Email</div>
        <mat-list-item>
          <mat-icon mat-list-icon>email</mat-icon>
          <div mat-line>contacto@buscoayuda.org</div>
        </mat-list-item>
    </mat-list>
      <br>
      <br>
    <h2>¿Eres una organización y te interesa ayudar?</h2>
    <p><strong>!Afíliate¡</strong> y juntos trabajemos para mejorar las condiciones de vida de las personas.</p>
</div>
