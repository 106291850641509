<footer>
    <div class="container">
        <div class="item center middle">
            <img src="/assets/img/logo.png" alt="logimg" width="45%">
        </div>
        <div class="item">
            <mat-nav-list role="option">
                <a mat-list-item [routerLink]="['/afiliacion']">
                    <mat-icon>post_add</mat-icon>
                    &nbsp;Afiliación
                </a>
                <a mat-list-item [routerLink]="['/preguntas-frecuentes']">
                    <mat-icon>help_outline</mat-icon>
                    &nbsp;Preguntas Frecuentes
                </a>
                <a mat-list-item (click)="openTerminosCondiciones()" id="btnTerminosCondiciones">
                    <mat-icon>policy</mat-icon>
                    &nbsp;Terminos y condiciones
                </a>
                <a mat-list-item (click)="openAvisoPrivacidad()" id="btnAvisoPrivacidad">
                    <mat-icon>privacy_tip</mat-icon>
                    &nbsp;Aviso de privacidad
                </a>
            </mat-nav-list>           
        </div>
        <div class="item x2 center">
            <img src="/assets/img/logos/logo-socialydigital.png" alt="social" height="auto">
            <img src="/assets/img/logos/logo-fundacion-enter.png" alt="logo" height="auto">
            <!--<img src="/assets/img/logos/logo-qro.png" alt="qro" height="auto">-->
            <!--<img src="/assets/img/logos/secretaria-300x89.png" alt="secret" height="auto">-->
            <div style="float: right; text-align: left; font-size: 0.8em;">
                Busco Ayuda es una aplicación desarrollada por Fundación Enter AC. como parte de su modelo de atención que busca
                coadyuvar para el cierre de la brecha digital en organizaciones de la sociedad civil y personas de grupos vulnerables.
                Todos los derechos reservados Querétaro, México 2019.
                
            </div>
        </div>
        
    </div>
</footer>

<button mat-fab color="primary" aria-label="Volver arriba" id="btnTop" onclick="window.scrollTo(0,0)">
    <mat-icon>keyboard_arrow_up</mat-icon>
</button>