import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preguntas-frecuentes',
  templateUrl: './preguntas-frecuentes.component.html',
  styleUrls: ['./preguntas-frecuentes.component.scss']
})
export class PreguntasFrecuentesComponent implements OnInit {
  panelOpenState = false;
  step = 0;
  title="Preguntas frecuentes"
  constructor() { }

  ngOnInit(): void {
    setTimeout(function(){document.getElementById("btnTop").click();},0);
  }

  openPolitica(){
    document.getElementById('btnAvisoPrivacidad').click()
  }

  openTerminosCondiciones(){
    document.getElementById('btnTerminosCondiciones').click()
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}
