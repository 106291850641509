import { Component, OnInit } from '@angular/core';
import { MysqlService } from 'src/app/services/mysql.service';

@Component({
  selector: 'app-afiliacion',
  templateUrl: './afiliacion.component.html',
  styleUrls: ['./afiliacion.component.scss']
})
export class AfiliacionComponent implements OnInit {
  title="Afiliación";
  constructor(private _mysql:MysqlService) { }

  ngOnInit(): void {
    setTimeout(function(){document.getElementById("btnTop").click();},0);
  }

  public getUrlsocialydigital(){
    return this._mysql.getUrlsocialydigital();
  }



}
