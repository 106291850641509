<app-subtitle [titulo]="title" [imagen]="'/assets/img/image-banner-problematica.jpg'"></app-subtitle>
<div class="feature free-text">
    <h1> ¿Eres una organización que quiere ayudar? </h1>
    <p>Afíliate y trabajemos en mejorar las condiciones de vida de las personas. </p>

    <form (ngSubmit)="sendForm()" [formGroup]="formOsc">
        <div class="bt col-md-6 float-left">
            <mat-radio-group aria-label="Select an option" formControlName="tipo">
                <mat-radio-button value="1">Soy OSC</mat-radio-button>
                &nbsp;
                <mat-radio-button value="2">Soy Privado</mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-form-field appearance="fill" hintLabel="Formatos permitidos (jpg y png)" class="col-md-6">
            <mat-label>Logo (Opcional)</mat-label>
            <input matInput [type]="'text'" placeholder="Ningún archivo seleccionado" value="{{logo.value}}" readonly>
            <button type="button" mat-icon-button matSuffix (click)="logo.click()">
                <mat-icon>attach_file</mat-icon>
            </button>
            <input (change)="onFileChange($event)" class="hide" #logo id="input-file-id" type="file"  accept="image/png, image/jpeg" formControlName="logo"/>
        </mat-form-field>


        <mat-form-field appearance="fill" hintLabel="Máximo 300 caracteres" class="col-md-6">
            <mat-label>* Nombre</mat-label>
            <input matInput formControlName="nombre" #nombre maxlength="300">
            <mat-hint align="end">{{nombre.value?.length || 0}}/300</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-md-6">
            <mat-label>* Figura</mat-label>
            <mat-select formControlName="figura">
                <mat-option value="A.C.">A.C.</mat-option>
                <mat-option value="I.A.P.">I.A.P.</mat-option>
                <mat-option value="I.B.P.">I.B.P.</mat-option>
                <mat-option value="A.B.P.">A.B.P.</mat-option>
                <mat-option value="F.B.P.">F.B.P.</mat-option>
                <mat-option value="I.A.S.P.">I.A.S.P.</mat-option>
                <mat-option value="S.C.">S.C.</mat-option>
                <mat-option value="S.A. de C.V.">S.A. de C.V.</mat-option>
                <mat-option value="S. de R.L. de C.V.">S. de R.L. de C.V.</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-md-3">
            <mat-label>* Estado</mat-label>
            <mat-select formControlName="estado">
                <mat-option *ngFor="let estado of estados" value="{{estado.id}}">{{estado.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" hintLabel="Máximo 100 caracteres" class="col-md-3">
            <mat-label>* Municipio</mat-label>
            <input matInput formControlName="municipio" #municipio maxlength="100">
            <mat-hint align="end">{{municipio.value?.length || 0}}/100</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" hintLabel="Máximo 150 caracteres" class="col-md-6">
            <mat-label>* Dirección</mat-label>
            <input matInput formControlName="direccion" maxlength="150" #direccion>
            <mat-hint align="end">{{direccion.value?.length || 0}}/150</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" hintLabel="Máximo 5 caracteres" class="col-md-3">
            <mat-label>* CP</mat-label>
            <input type="number" matInput formControlName="cp" #cp maxlength="5">
            <mat-hint align="end">{{cp.value?.length || 0}}/5</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" hintLabel="Máximo 10 caracteres" class="col-md-3">
            <mat-label>* Teléfono</mat-label>
            <input type="tel" matInput formControlName="telefono" #telefono maxlength="10">
            <mat-hint align="end">{{telefono.value?.length || 0}}/10</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" hintLabel="Máximo 13 caracteres" class="col-md-6">
            <mat-label>* RFC</mat-label>
            <input type="text" matInput formControlName="rfc" maxlength="13" #rfc>
            <mat-hint align="end">{{rfc.value?.length || 0}}/13</mat-hint>
        </mat-form-field>


        <mat-form-field appearance="fill" hintLabel="Máximo 50 caracteres" class="col-md-6">
            <mat-label>* E-mail</mat-label>
            <input matInput formControlName="email" maxlength="50" #email>
            <mat-hint align="end">{{email.value?.length || 0}}/50</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" hintLabel="Máximo 250 caracteres" class="col-md-6">
            <mat-label>Sitio Web / Red social (Opcional)</mat-label>
            <input type="url" matInput formControlName="web" maxlength="250" #web>
            <mat-hint align="end">{{web.value?.length || 0}}/250</mat-hint>
        </mat-form-field>

        <h1>Contacto 1</h1>

        <mat-form-field appearance="fill" hintLabel="Máximo 100 caracteres" class="col-md-6">
            <mat-label>Nombre</mat-label>
            <input type="text" matInput formControlName="contacto1Nombre" #contacto1Nombre maxlength="100">
            <mat-hint align="end">{{contacto1Nombre.value?.length || 0}}/100</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" hintLabel="Máximo 50 caracteres" class="col-md-6">
            <mat-label>Correo</mat-label>
            <input type="text" matInput formControlName="contacto1Correo" #contacto1Correo maxlength="50">
            <mat-hint align="end">{{contacto1Correo.value?.length || 0}}/50</mat-hint>
        </mat-form-field>

        <h1>Contacto 2</h1>

        <mat-form-field appearance="fill" hintLabel="Máximo 100 caracteres" class="col-md-6">
            <mat-label>Nombre</mat-label>
            <input type="text" matInput formControlName="contacto2Nombre" #contacto2Nombre maxlength="100">
            <mat-hint align="end">{{contacto2Nombre.value?.length || 0}}/100</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" hintLabel="Máximo 50 caracteres" class="col-md-6">
            <mat-label>Correo</mat-label>
            <input type="text" matInput formControlName="contacto2Correo" #contacto2Correo maxlength="50">
            <mat-hint align="end">{{contacto2Correo.value?.length || 0}}/50</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" hintLabel="Máximo 1,500 caracteres" class="col-md-12">
            <mat-label>* Presentación</mat-label>
            <textarea matInput formControlName="presentacion" #presentacion maxlength="1500"></textarea>
            <mat-hint align="end">{{presentacion.value?.length || 0}}/1,500</mat-hint>
        </mat-form-field>

        <h1>Grupos de Atención</h1>

        <div>
            <mat-selection-list #shoes formControlName="gruposdeatencion">
                <mat-list-option *ngFor="let grupo of gruposAtencion" value="{{grupo}}">
                {{grupo}}
                </mat-list-option>
            </mat-selection-list>
        </div>
        <br>
        <mat-form-field appearance="fill" hintLabel="Máximo 4 caracteres" class="col-md-3">
            <mat-label>* Núm. de Beneficiarios</mat-label>
            <input type="number" matInput formControlName="numBeneficiarios" #numBeneficiarios maxlength="4">
            <mat-hint align="end">{{numBeneficiarios.value?.length || 0}}/4</mat-hint>

        </mat-form-field>


        <mat-form-field appearance="fill" hintLabel="Máximo 4 caracteres" class="col-md-3">
            <mat-label>* Núm. de Empleados</mat-label>
            <input type="number" matInput formControlName="numEmpleados" #numEmpleados maxlength="4">
            <mat-hint align="end">{{numEmpleados.value?.length || 0}}/4</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-md-6">
            <mat-label>* Línea de Acción</mat-label>
            <mat-select formControlName="lineaDeAccion" multiple >
                <mat-select-trigger>
                    {{form.lineaDeAccion.value ? form.lineaDeAccion.value[0] : ''}}
                    <span *ngIf="form.lineaDeAccion.value?.length > 1" class="example-additional-selection">
                      (+{{form.lineaDeAccion.value.length - 1}} {{form.lineaDeAccion.value?.length === 2 ? 'otro' : 'otros'}})
                    </span>
                </mat-select-trigger>
                <mat-option *ngFor="let linea of lineas" value="{{linea.nombre}}">{{linea.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="col-md-12">
            <mat-label>* Problemáticas que atiende</mat-label>

            <mat-select formControlName="problematicas" multiple >
                
                <mat-option *ngFor="let problematica of problematicas" value="{{problematica.id}}">{{problematica.nombre}}</mat-option>
            </mat-select>

            <!--<textarea matInput formControlName="problematicas" #problematicas maxlength="500"></textarea>
            <mat-hint align="end">{{problematicas.value?.length || 0}}/500</mat-hint>-->
        </mat-form-field>


        <!--<mat-form-field appearance="fill" hintLabel="Máximo 500 caracteres" class="col-md-12">
            <mat-label>* Servicios que Presta</mat-label>
            <textarea matInput formControlName="servicios" #servicios maxlength="500"></textarea>
            <mat-hint align="end">{{servicios.value?.length || 0}}/500</mat-hint>
        </mat-form-field>-->


        <mat-form-field class="example-chip-list col-md-12" appearance=fill>
            <mat-label>* Servicios que presta</mat-label>
            <mat-chip-list #chipList1 aria-label="Fruit selection">
              <mat-chip *ngFor="let servicio of servicios" [selectable]="selectable"
                       [removable]="removable" (removed)="remove2(servicio)">
                {{servicio.name}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input formControlName="service" placeholder=""
                     [matChipInputFor]="chipList1"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="addOnBlur"
                     (matChipInputTokenEnd)="add2($event)">
            </mat-chip-list>
        </mat-form-field>

        <!--<mat-form-field appearance="fill" hintLabel="Máximo 500 caracteres" class="col-md-12">
            <mat-label>* Sugerencias para Recibir Atención</mat-label>
            <input type="text" matInput formControlName="sugerencias" #sugerencias maxlength="500">
            <mat-hint align="end">{{sugerencias.value?.length || 0}}/500</mat-hint>
        </mat-form-field>-->

        <mat-form-field class="example-chip-list col-md-12" appearance=fill>
            <mat-label>* Sugerencias para recibir atención</mat-label>
            <mat-chip-list #chipList aria-label="Fruit selection">
              <mat-chip *ngFor="let fruit of fruits" [selectable]="selectable"
                       [removable]="removable" (removed)="remove(fruit)">
                {{fruit.name}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input formControlName="suggest" placeholder=""
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="addOnBlur"
                     (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
        </mat-form-field>
          
        
        <br><br><br>
        <button type="submit" mat-raised-button color="primary" class="flat">Enviar</button>
    </form>


</div>