// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//pro
export const environment = {
  production: true,
  apiImagenes: "https://api.socialydigital.org/storage",
  apiUrl: "https://api.socialydigital.org/api/",
  url: "https://api.socialydigital.org/",
  demakUrl: "https://api.socialydigital.org/api/",
  url_socialydigital: "https://www.socialydigital.org/"
};

//cer
// export const environment = {
//   production: false,
//   apiImagenes: "https://api.socialydigital.cer.3demak.com/storage",
//   apiUrl: "https://api.socialydigital.cer.3demak.com/api/",
//   url: "http://127.0.0.1:8000",
//   demakUrl: "https://3demak.com/",
//   url_socialydigital: "https://socialydigital.3demak.com/"
// };

















//old
/*export const environment = {
  production: false,
  apiImagenes: "http://api.socialydigital.3demakdevserv.com/storage/app",
  apiUrl: "http://api.socialydigital.3demakdevserv.com/public/api/",
  url: "http://127.0.0.1:8000",
  demakUrl: "https://3demak.com/",
};*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
