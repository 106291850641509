<div class="feature bg-manos">
    <h1>La <span class="remarcar">ayuda</span> esta mas cerca de lo que pensamos</h1>
    <div>
        <form (ngSubmit)="sendForm()" [formGroup]="formBusqueda">
            <mat-form-field appearance="fill">
                <mat-label>Buscador de Problemática</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput placeholder="" #busqueda [matAutocomplete]="auto" (keyup)="buscarProblematica(busqueda.value)" formControlName="problematica">
                <mat-hint></mat-hint>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of options" value="{{option['nombre_problema']}}">
                        <!--[value]="option['nombre_problema']" -->
                      {{option['nombre_problema']}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Estado</mat-label>
                <mat-icon matPrefix>location_on</mat-icon>
                <mat-select #estados formControlName="estado" (selectionChange)="municipioEstado()">
                    <mat-option *ngFor="let estado of comboEstados" value="{{estado.id_country_state_pk}}">{{estado.nombre}}</mat-option>
                </mat-select>
                <mat-hint></mat-hint>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Municipio</mat-label>
                <mat-icon matPrefix>location_on</mat-icon>
                <mat-select #municipio formControlName="municipio">
                    <mat-option *ngFor="let municipio of municipios" value="{{municipio.id_municipalities_pk}}">{{municipio.nombre}}</mat-option>
                </mat-select>
                <mat-hint></mat-hint>
            </mat-form-field>

            <button mat-raised-button color="primary" type="submit" class="flat">Buscar</button>
        </form>
    </div>
</div>
<div class="feature info">
    <div class="layer">
        <div class="description">
            <h1>buscoayuda.org</h1>
            <p>Somos una plataforma digital, pensada en acercarte a las organizaciones de la sociedad civil, que pueden ayudarte de forma profesional. Todos estamos expuestos a sufrir acontecimientos que nos pueden llegar a afectar, y la información no debería ser una barrera para nuestro desarrollo humano. Es por ello que nos gustaría presentarnos contigo, porque queremos formar parte de tu vida.</p>
        </div>
        <div class="image"></div>
    </div>
</div>
<div class="feature disclaimer">
    <p>Este servicio es gratuito para el público y para las Organizaciones de la Sociedad Civil y su funcionamiento es posible gracias a las aportaciones voluntarias de personas y empresarios que comparten nuestra visión.</p>
</div>
