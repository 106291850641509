import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ErrorServidorComponent } from 'src/app/alertas/error-servidor/error-servidor.component';
import { MysqlService } from 'src/app/services/mysql.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [MysqlService]
})
export class HomeComponent implements OnInit {

  public municipios = [];
  comboEstados = [];
  options: any[] = [];
  public problematicas: any[] = [];
  filteredOptions: Observable<string[]>;

  formBusqueda = new FormGroup({
    problematica: new FormControl('', [Validators.required]),
    estado: new FormControl('', [Validators.required]),
    municipio: new FormControl('', [])
  });

  constructor(private mysql_service: MysqlService, private route: Router, public dialog: MatDialog) { }
  ngOnInit(): void {
    this.buscar();
    setTimeout(function () { document.getElementById("btnTop").click(); }, 0);
    this.estadosDeMexico();
  }

  sendForm() {
    if (this.formBusqueda.valid) {

      this.obtenerIdProblematica();
      // console.info(this.formBusqueda.getRawValue());


      // console.log(this.options.length);
    
      if(!this.formBusqueda.value.municipio){
        this.formBusqueda.value.municipio=0;
      }
      this.route.navigate(["/busqueda",
        this.formBusqueda.value.problematica,
        this.formBusqueda.value.estado,
        this.formBusqueda.value.municipio,

      ]);

    }
  }

  public obtenerIdProblematica() {
    let id = 0;
    this.options.forEach(element => {
      if (element.nombre_problema == this.formBusqueda.value.problematica) {
        this.formBusqueda.value.problematica = element.id_cat_problem_attend_pk;
      }
    });
  }
  public buscar() {
    this.mysql_service.getProblematicas().subscribe(result => {
      console.log(result);
      for (let i = 0; i < result.data.length; i++) {
        this.options.push(result.data[i]);
        console.log(result.data[i]);
      }
      this.problematicas = this.options;
      console.log(this.problematicas.length);
    }, error => {
      this.dialog.open(ErrorServidorComponent);
    })
  }

  public buscarProblematica(palabra: string) {
    if (palabra == "") {
      this.options = this.problematicas;
    } else {
      this.options = [];
      for (let i = 0; i < this.problematicas.length; i++) {
        console.log(this.problematicas[i]);
        if (this.problematicas[i].nombre_problema.toLowerCase().includes(palabra.toLowerCase())) {
          this.options.push(this.problematicas[i]);
        }
      }
    }
  }

  public estadosDeMexico() {
    this.mysql_service.getEstados().subscribe(result => {
      if (result.estado == 200) {
        this.comboEstados = result.data;
      } else {
        this.dialog.open(ErrorServidorComponent);
      }

    }, error => {
      //console.error(error);
      this.dialog.open(ErrorServidorComponent);
    });
  }

  public municipioEstado() {
    this.mysql_service.getMunicipio(this.formBusqueda.value).subscribe(result => {
      console.log(result);
      if (result.estado == 200) {
        this.municipios = result.data;
      } else {
        this.dialog.open(ErrorServidorComponent);
      }

    }, error => {
      //console.error(error);
      this.dialog.open(ErrorServidorComponent);
    });
  }
}
