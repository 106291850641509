import { Component, OnInit } from '@angular/core';
import { MysqlService } from 'src/app/services/mysql.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface Organizaciones {
  id: number;
  organizacion: string;
  tipo_organizacion: string
  nombre: string;
  tipo: string;
  ciudad: string;
}


const tipo = [];
// const tipo = [{id:"1",nombre: "Público"},{id:"2",nombre: "Privado"}];
const estados = [];

const ELEMENT_DATA: Organizaciones[] = [];

@Component({
  selector: 'app-resultados-busqueda',
  templateUrl: './resultados-busqueda.component.html',
  styleUrls: ['./resultados-busqueda.component.scss']
})
export class ResultadosBusquedaComponent implements OnInit {
  spinner_inicial=true;
  vermas = false;
  title = "Encuentra la ayuda que buscas";
  displayedColumns: string[] = ['organizacion','tipo_organizacion', 'nombre', 'tipo', 'ciudad', 'informacion'];
  dataSource = ELEMENT_DATA;
  isLoading = true;
  definicion: any;
  rows = 0;
 
  public id_estado: number;
  public id_municipio: number;
  public id_problematica: number;


  constructor(private _mysql: MysqlService,
    private router: Router,
    private activatedRoute: ActivatedRoute


  ) { }
  endpoint =environment.apiImagenes;
  ngOnInit(): void {
    this.obteniendoDatosUrl();

    /*setTimeout(function(){document.getElementById("btnTop").click();},0);

    let problematica = this.activatedRoute.snapshot.paramMap.get('problematica');
    let estado = this.activatedRoute.snapshot.paramMap.get('estado');
    this.buscar(problematica,parseInt(estado));*/
   
    this.buscar(this.id_problematica);
  this.spinner_inicial=false;
  }

  public getUrlApiImagenes(){
    return this._mysql.getUrlApiImagenes();
  }

  buscar(problematica: Number) {
    this.isLoading = true;
    this._mysql.getProblematica(problematica).subscribe(result => {
      console.log(result);
      this.definicion = result.data;
      this.isLoading = false;
    }, error => {
      console.info(error);
      this.isLoading = false;
    });
    
    this.isLoading = true;
    this._mysql.getInsitucionesProblematicas(this.id_problematica,this.id_estado,this.id_municipio).subscribe(result=>{
      this.dataSource = this.acoplarResultados(result);
      this.rows = result.size;
      this.rows=this.dataSource.length ;

    });
    /*  this._mysql.getOscs(problematica, estado).subscribe(result => {
        console.info(result);
      //  this.dataSource = this.acoplarResultados(result);
     //   this.isLoading = false;
        this.rows = result.size;
        this.definicion = result.definicion;
      }, error => {
        console.info(error);
        this.isLoading = false;
      });*/
  }
  acoplarResultados(result: any) {
    let transformacion: Organizaciones[] = [];
    for (let i = 0; i < result.data['institucion'].length; i++) {
       transformacion.push({ id: result.data['institucion'][i].id_organization_pk, organizacion: this.endpoint + result.data['institucion'][i].logo_directorio, tipo_organizacion: 'Matriz',nombre: result.data['institucion'][i].informacion_general.nombre, tipo: result.data['institucion'][i].tipo_organizacion.nombre_org, ciudad: result.data['institucion'][i].municipios.nombre})
     }
     for(let j=0; j<result.data['sucursal'].length; j++){
      transformacion.push({ id: result.data['sucursal'][j].id_branch_offices_pk, organizacion: this.endpoint + result.data['sucursal'][j]?.organizacion?.logo_directorio, tipo_organizacion: 'Sucursal',nombre: result.data['sucursal'][j].informacion_general.nombre, tipo: result.data['sucursal'][j]?.organizacion?.tipo_organizacion.nombre_org, ciudad: result.data['sucursal'][j].municipio.nombre})
     }    
     this.isLoading = false;
    return transformacion;
  }

  
  replaceAll(cadena: String) {
    if (cadena != null) {
      let re = /\n/gi;
      return cadena.replace(re, "<br>");
    } else {
      return "";
    }
  }

  toLowerCase(cadena: String): String {
    return cadena.toLowerCase();
  }


  obteniendoDatosUrl() {
    this.activatedRoute.params.subscribe(
      params => {
        this.id_estado = +params['estado'];
        this.id_municipio = +params['municipio'];
        this.id_problematica = +params['problematica'];
      }
    );
  }
}

