<h2 mat-dialog-title>Terminos y condiciones</h2>
<mat-dialog-content class="mat-typography">
    <p>
        Buscoayuda.org es un servicio digital de orientación para el publico en general.
    </p>
    <p>
        Buscoayuda.org es un servicio gratuito bajo los siguientes términos:
    </p>
    <ul>
        <li>Para el público en general al cual denominamos beneficiarios.</li>
        <li>Para las Organizaciones de la Sociedad Civil previamente registradas y que hayan podido ser validadas en su constitución legal, objeto social, registro ante la SHCP, portafolio de servicios asistenciales y con- firmación de la veracidad en la prestación de sus servicios.</li>
    </ul>
    <p>
        Buscoayuda.org cobrará una cuota de afiliación bajo los siguientes términos.
    </p>
    <ul>
        <li>Para Organizaciones Privadas previamente registradas y que hayan podido ser validadas en su con- stitución legal, objeto social, registro ante la SHCP, portafolio de servicios asistenciales y confirmación de la veracidad en la prestación de sus servicios deberá cubrir una membresía anual por $20,000 pesos y $5,000 pesos adicionales por cada ciudad en la que declare tener presencia.</li>
    </ul>
    <p>
        Aunque buscoayuda.org realiza esfuerzos para que a través de su proceso de afiliación se verifique la veracidad de la información, nos deslindamos de cualquier responsabilidad sobre actos deshonestos en los que pudieran incurrir las organizaciones afiliadas.
    </p>
    <p>
        Buscoayuda.org es un servicio de orientación y vinculación entre particulares, en ningún momento la información expresada en este sitio web sugiere, recomienda o indica tratamientos para el beneficiario. Tampoco realiza labores de promoción de organizaciones, empresas, clínicas mas allá de la información institucional como: Nombre, Objeto Social, Historia de la Organización, Servicios y Datos de Contacto. Cualquier arreglo, acuerdo o contrato para la prestación de servicios entre los afiliados y los beneficiari- os es exclusivamente responsabilidad entre las partes
    </p>
    <p>
        Buscoayuda.org declara no recibir ninguna comisión o beneficio directo sobre ningún acto de arreglo, acuerdo o contrato de prestación de servicios entre los afiliado s y los beneficiarios.
    </p>
    
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!--<button mat-button mat-dialog-close>Cancel</button>-->
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial class="flat">Cerrar</button>
</mat-dialog-actions>