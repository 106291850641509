import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { EmailValidator, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoaderComponent } from 'src/app/componentes/loader/loader.component';
import { MysqlService } from '../../../services/mysql.service';
import { MessageBoxComponent } from '../../message-box/message-box.component';

export interface DialogData {
  animal: 'panda' | 'unicorn' | 'lion';
}

@Component({
  selector: 'app-proceso-de-afiliacion',
  templateUrl: './proceso-de-afiliacion.component.html',
  styleUrls: ['./proceso-de-afiliacion.component.scss']
})

export class ProcesoDeAfiliacionComponent implements OnInit {
  title = "Proceso de afiliación";
  gruposAtencion = ["Niños","Jóvenes","Adultos","3era. Edad","Animales","Oscs","Migración","Pueblos originarios y comunidades indígenas","Medio ambiente y animales","Defensa y promoción derechos humanos"];
  constructor(private router:Router,private _service:MysqlService, private dialog:MatDialog) { }

  formOsc = new FormGroup({
    tipo: new FormControl('1',[Validators.required]),
    logo: new FormControl('',[]),
    fileSource: new FormControl('', ),
    nombre: new FormControl('',[Validators.required,Validators.maxLength(100)]),
    figura: new FormControl('',[Validators.required]),
    estado: new FormControl('',[Validators.required]),
    municipio: new FormControl('',[Validators.required,Validators.maxLength(100)]),
    direccion: new FormControl('',[Validators.required]),
    cp: new FormControl('',[Validators.required ]),
    telefono: new FormControl('',[Validators.required]),
    rfc: new FormControl('',[Validators.required]),
    email: new FormControl('',[Validators.required,Validators.email]),
    web: new FormControl('',[]),
    presentacion: new FormControl('',[Validators.required]),
    gruposdeatencion: new FormControl('',[]),
    numBeneficiarios: new FormControl('',[Validators.required]),
    numEmpleados: new FormControl('',[Validators.required]),
    lineaDeAccion: new FormControl('',[Validators.required]),
    problematicas:new FormControl('',[Validators.required]),
    servicios:new FormControl('',[Validators.required]),
    sugerencias:new FormControl('',[Validators.required]),
    contacto1Nombre:new FormControl('',[]),
    contacto1Correo:new FormControl('',[Validators.email]),
    contacto2Nombre:new FormControl('',[]),
    contacto2Correo:new FormControl('',[Validators.email]),
    service: new FormControl('',[Validators.required]),
    suggest: new FormControl('',[Validators.required]),
  });
  problematicas:any;
  ngOnInit(): void {
    setTimeout(function(){document.getElementById("btnTop").click();},0);
    setTimeout(function(){document.getElementById("btnAvisoPrivacidad").click();},900);

    this._service.getCatalogoProblematicas().subscribe(result=>{
      this.problematicas = result.datos;
    },error=>{
      console.info(error);
    });
  }

  get form(){ 
    return this.formOsc.controls;
  }
  sendForm(){
    this.form.sugerencias.setValue(this.fruits);
    this.form.servicios.setValue(this.servicios);
    console.info(this.formOsc.getRawValue());

    const formData = new FormData();

    formData.append('imagen', this.formOsc.get('fileSource').value);
    let id =0;
    if(this.formOsc.valid){
      this.openDialog();
      this._service.setOsc(this.formOsc.getRawValue()).subscribe(result=>{
        console.info(result);
        this.cargarLogo(formData,result.id,result.mensaje);
      },error=>{
        console.info(error);
        this.closeDialog();
      });
    }else{
      this.openMessage2("Existen errores en los datos ingresados por favor valide o complete la información segun sea el caso, hemos marcado en rojo los errores para usted.");
    }
  }
  cargarLogo(formData:FormData,id:string,mensaje:String){
    formData.append('id', id);

    this._service.cargarLogo(formData).subscribe(result=>{
      this.openMessage(mensaje);
      this.closeDialog();
    },error=>{
      this.closeDialog();
    });
  }
  openMessage(mensaje:String): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      
      data: {message: mensaje}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(["/inicio"])
      
    });
  }

  openMessage2(mensaje:String): void {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      
      data: {message: mensaje}
    });
  }

  estados = [
    {"id":"1","nombre":"Aguascalientes","abrevia":"Ags."},
    {"id":"2","nombre":"Baja California","abrevia":"BC."},
    {"id":"3","nombre":"Baja California Sur","abrevia":"BCS."},
    {"id":"4","nombre":"Campeche","abrevia":"Camp."},
    {"id":"5","nombre":"Chiapas","abrevia":"Chis."},
    {"id":"6","nombre":"Chihuahua","abrevia":"Chih."},
    {"id":"7","nombre":"Coahuila","abrevia":"Coah."},
    {"id":"8","nombre":"Colima","abrevia":"Col."},
    {"id":"9","nombre":"Ciudad de México","abrevia":"CDMX."},
    {"id":"10","nombre":"Durango","abrevia":"Dgo."},
    {"id":"11","nombre":"México","abrevia":"Méx."},
    {"id":"12","nombre":"Guanajuato","abrevia":"Gto."},
    {"id":"13","nombre":"Guerrero","abrevia":"Gro."},
    {"id":"14","nombre":"Hidalgo","abrevia":"Hgo."},
    {"id":"15","nombre":"Jalisco","abrevia":"Jal."},
    {"id":"16","nombre":"Michoacán","abrevia":"Mich."},
    {"id":"17","nombre":"Morelos","abrevia":"Mor."},
    {"id":"18","nombre":"Nayarit","abrevia":"Nay."},
    {"id":"19","nombre":"Nuevo León","abrevia":"NL."},
    {"id":"20","nombre":"Oaxaca","abrevia":"Oax."},
    {"id":"21","nombre":"Puebla","abrevia":"Pue."},
    {"id":"22","nombre":"Querétaro","abrevia":"Qro."},
    {"id":"23","nombre":"Quintana Roo","abrevia":"QR."},
    {"id":"24","nombre":"San Luis Potosí","abrevia":"SLP."},
    {"id":"25","nombre":"Sinaloa","abrevia":"Sin."},
    {"id":"26","nombre":"Sonora","abrevia":"Son."},
    {"id":"27","nombre":"Tabasco","abrevia":"Tab."},
    {"id":"28","nombre":"Tamaulipas","abrevia":"Tamps."},
    {"id":"29","nombre":"Tlaxcala","abrevia":"Tlax."},
    {"id":"30","nombre":"Veracruz","abrevia":"Ver."},
    {"id":"31","nombre":"Yucatán","abrevia":"Yuc."},
    {"id":"32","nombre":"Zacatecas","abrevia":"Zac."},
    {"id":"33","nombre":"Extranjero","abrevia":"Ext."}
    ];
  lineas = [
    {"nombre":"Asistencia social"},
    {"nombre":"Asistencia jurídica"},
    {"nombre":"Atención de la salud y cuestiones sanitarias"},
    {"nombre":"Apoyo a la alimentación popular"},
    {"nombre":"Apoyo en la defensa y promoción de los derechos humanos"},
    {"nombre":"Atención a personas con discapacidad"},
    {"nombre":"Desarrollo de los pueblos indígenas"},
    {"nombre":"Desarrollo comunitario urbano y rural"},
    {"nombre":"Defensa de los derechos de los consumidores"},
    {"nombre":"Fomento educativo, cultural, artístico, científico y tecnológico"},
    {"nombre":"Fomento de acciones para mejorar la economía popular"},
    {"nombre":"Fortalecimiento de otras organizaciones de la sociedad civil"},
    {"nombre":"Fortalecimiento del tejido social y seguridad ciudadana"},
    {"nombre":"Promoción de la participación ciudadana"},
    {"nombre":"Promoción de la equidad de género"},
    {"nombre":"Promoción del deporte"},
    {"nombre":"Protección del medio ambiente"},
    {"nombre":"Protección civil"},
    {"nombre":"Otras"}
  ];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fruits: Fruit[] = [
    
  ];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: Fruit): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  servicios: Fruit[] = [
    
  ];

  add2(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our servicio
    if ((value || '').trim()) {
      this.servicios.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove2(servicio: Fruit): void {
    const index = this.servicios.indexOf(servicio);

    if (index >= 0) {
      this.servicios.splice(index, 1);
    }
  }

  onFileChange(event) {

    if (event.target.files.length > 0) {

      const file = event.target.files[0];

      this.formOsc.patchValue({

        fileSource: file

      });

    }

  }
  loader;
  openDialog() {
    this.loader = this.dialog.open(LoaderComponent, {
      data: {
        animal: 'panda'
      },
      disableClose:true,
    });
  }
  closeDialog(){
    this.loader.close();
  }
  
}
export interface Fruit {
  name: string;
}
