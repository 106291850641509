<header id="header">
    <mat-toolbar color="primary" id="menu" class="top">
        <a routerLink="/">
            <img src="/assets/img/logo.png" alt="logo-busco-ayuda" class="logo">
        </a>
    <span class="example-spacer"></span>
    <a mat-button [routerLink]="['/inicio']" routerLinkActive="active-link" class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
        <mat-icon>home</mat-icon>
        Inicio
    </a>
    <a mat-button [routerLink]="['/quienes-somos']" routerLinkActive="active-link" class="example-icon" aria-label="Example icon-button with share icon">
        <mat-icon>people_outline</mat-icon>
        Quíenes Somos
    </a>
    <a mat-button [routerLink]="['/afiliacion']" routerLinkActive="active-link" class="example-icon" aria-label="Example icon-button with share icon">
        <mat-icon>post_add</mat-icon>
        Afiliación
    </a>
    <a mat-button [routerLink]="['/preguntas-frecuentes']" routerLinkActive="active-link" class="example-icon" aria-label="Example icon-button with share icon">
        <mat-icon>help_outline</mat-icon>
        Preguntas Frecuentes
    </a>
    <a mat-button [routerLink]="['/contacto']" routerLinkActive="active-link" class="example-icon" aria-label="Example icon-button with share icon">
        <mat-icon>mail_outline</mat-icon>
        Contacto
    </a>

    <button mat-icon-button aria-label="" [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/inicio']" routerLinkActive="active-link">
            <mat-icon>home</mat-icon>Inicio
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item [routerLink]="['/quienes-somos']" routerLinkActive="active-link">
            <mat-icon>people_outline</mat-icon>Quíenes Somos
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item [routerLink]="['/afiliacion']" routerLinkActive="active-link">
            <mat-icon>post_add</mat-icon>Afiliación
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item [routerLink]="['/preguntas-frecuentes']" routerLinkActive="active-link">
            <mat-icon>help_outline</mat-icon>Preguntas Frecuentes
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item [routerLink]="['/contacto']" routerLinkActive="active-link">
            <mat-icon>mail_outline</mat-icon>Contacto
        </button>
    </mat-menu>
    </mat-toolbar>
</header>
