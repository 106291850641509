<app-subtitle [titulo]="title" [imagen]="'/assets/img/imagen-otros-links.jpg'"></app-subtitle>
<div class="feature free-text">
  <mat-accordion>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Tienen líneas de emergencia?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Las líneas de emergencia son del Estado de Querétaro</p>
      <table>
        <caption></caption>
        <tr>
          <th id="emergencia"> EMERGENCIAS</th>
          <td>066</td>
        </tr>
        <tr>
          <th id="hospital">Hospital ISSSTE</th>
          <td>216.99.43</td>
        </tr>
        <tr>
          <th id="imsshospital">Hospital IMSS</th>
          <td>229.05.45</td>
        </tr>
        <tr>
          <th id="general">Hospital General</th>
          <td>216.00.39</td>
        </tr>
        <tr>
          <th id="clinica">Clínica de la UAQ en Santa Rosa Jáuregui</th>
          <td>291.11.63</td>
        </tr>
        <tr>
          <th id="cruz">Cruz Roja</th>
          <td>229.05.05</td>
        </tr>
        <tr>
          <th id="producto">Protección Civil Municipal</th>
          <td>217.07.02</td>
        </tr>
        <tr>
          <th id="protecion">Protección Civil del Estado</th>
          <td>309.14.30</td>
        </tr>
        <tr>
          <th id="bomberos">Bomberos</th>
          <td>212.3939</td>
        </tr>
        <tr>
          <th id="jurica">Bomberos Jurica</th>
          <td>218.13.00</td>
        </tr>
        <tr>
          <th id="seguridad">Seguridad Pública</th>
          <td>2.17.85.40</td>
        </tr>
      </table>

    </mat-expansion-panel>
    <!-- nuevas -->
    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Qué beneficios aporta la plataforma a la sociedad?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        Busco ayuda es una plataforma gratuita que permite encontrar información
        puntual (de contacto y servicios) de organizaciones de la sociedad civil
        legalmente constituidas, facilitando al usuario el camino a la búsqueda
        de apoyo ante una problemática social.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Cómo sé que las organizaciones son reales?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        Todas las organizaciones que aparecen en esta plataforma están
        debidamente verificadas en cuanto a su existencia, constitución y
        legalidad.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Cómo puedo ponerme en contacto con las organizaciones?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        Cada organización registra datos de contacto para que la comunidad pueda
        contactarles de manera directa.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Cómo puedo denunciar si una organización actúa mal?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        Contáctanos y envíanos tu denuncia a la siguiente dirección <a
          href="mailto:contacto@buscoayuda.org" target="_blank">
          contacto@buscoayuda.org</a> En un proceso de revisión se determinará
        si procede la baja de la organización en la plataforma.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Cómo puedo afiliarme si soy una organización de la sociedad
          civil?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        En nuestro menú de navegación podrás encontrar un breve instructivo para
        afiliarte a la plataforma. Si requieres de asistencia en el proceso de
        afiliación, ponte en contacto con nosotros.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Busco <a [routerLink]="['/inicio']">&nbsp;Ayuda.org&nbsp;</a>
          tiene algún costo para las organizaciones?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        Busco <a [routerLink]="['/inicio']">Ayuda.org </a>es una plataforma
        gratuita para las organizaciones y para las personas (usuarios) y su
        funcionamiento se da gracias a la aportación voluntaria de donativos de
        actores que comparten nuestros valores.
      </p>
    </mat-expansion-panel>




    <!-- fin nuevas -->




    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Cómo funciona la plataforma?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        Busco <a [routerLink]="['/inicio']">&nbsp;Ayuda.org&nbsp;</a> cuenta con
        un alcance a nivel nacional, vinculando información oportuna de
        organizaciones de la sociedad civil que atienen de manera profesional y
        formal diversas problemáticas sociales. La plataforma fue diseñada de
        manera que su navegación sea amigable con el usuario, ya que en 3 clicks
        llega a la información que busca.
      </p>
      <p>
        Te invitamos a revisar nuestro video tutorial que se encuentra en la
        página principal, sobre el funcionamiento de la plataforma.
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿En qué puede beneficiarme esta plataforma si soy una OSC?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Al formar parte de nuestra plataforma podrás elevar la presencia
        mejorando la visibilidad de la causa que atiendes como organización. Si
        deseas más información sobre como te beneficiaras al afiliarte a
        nosotros, por favor contáctanos.</p>
    </mat-expansion-panel>


    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Quiénes están detrás de este proyecto?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Fundación Enter A.C como un vinculante y organizaciones de la sociedad
        civil debidamente constituidas como actores de cambio.</p>
    </mat-expansion-panel>


    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Cuánto dinero cuesta afiliarme a esta plataforma?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>La afiliación a nuestra plataforma en esta primera etapa no
        representara un costo adicional a las organizaciones. Le invitamos a
        revisar nuestros términos y condiciones.</p>
    </mat-expansion-panel>


    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Esta página es confiable?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Nuestra página cumple con los requisitos legales establecidos por la
        Ley Federal de Protección de Datos Personales en Posesión de
        Particulares. De igual manera contamos con el protocolo seguro de
        transparencia de hipertexto (https).</p>
      <p>Te invitamos a conocer nuestra <a (click)="openPolitica()">política de
          privacidad</a> y nuestros <a (click)="openTerminosCondiciones()">términos
          y condiciones</a>.</p>
    </mat-expansion-panel>


    <mat-expansion-panel (opened)="panelOpenState= true"
      (closed)="panelOpenState= false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help_outline</mat-icon>
          &nbsp;¿Cómo puedo ponerme en contacto con ustedes?
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Puedes escribirnos un correo electrónico a la siguiente dirección: <a
          href="mailto:info@buscoayuda.org">info@buscoayuda.org</a> donde te
        responderemos en un tiempo no máximo de cinco días hábiles.</p>
    </mat-expansion-panel>


  </mat-accordion>

  <br><br>
  <mat-accordion>
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)"
      hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>bookmark_border</mat-icon>
          &nbsp;CLAUSULA DE RESPONSABILIDAD
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>Cláusula de responsabilidad: Busco Ayuda.org es una plataforma que
        actúa de buena fe, facilitando que las organizaciones
        de la sociedad civil no enfrenten barreras para difundir sus causas y
        sus acciones. Fundación Enter A.C, aplica filtros de
        manera regular para disminuir la posibilidad de mostrar información
        falsa o errónea. Toda la información que se muestra
        aquí es responsabilidad de las organizaciones que la publican. </p>
    </mat-expansion-panel>

  </mat-accordion>

</div>
