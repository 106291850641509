import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AvisoPrivacidadComponent } from 'src/app/pages/aviso-privacidad/aviso-privacidad.component';
import { TerminosCondicionesComponent } from 'src/app/pages/terminos-condiciones/terminos-condiciones.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public dialog: MatDialog) {}

  openAvisoPrivacidad(): void {
    const dialogRef = this.dialog.open(AvisoPrivacidadComponent, {
      width: '80%',
      data: {name: "", animal: ""}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      
    });
  }

  openTerminosCondiciones(): void {
    const dialogRef = this.dialog.open(TerminosCondicionesComponent, {
      width: '80%',
      data: {name: "", animal: ""}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      
    });
  }
  ngOnInit(): void {
  }

}
