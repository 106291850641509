<h2 mat-dialog-title>Aviso de privacidad</h2>
<mat-dialog-content class="mat-typography">
  <p>Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos de su conocimiento que Fundacion Enter A.C., con domicilio en Querétaro, México es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.
    Su información personal será utilizada para las siguientes finalidades: proveer los servicios y productos que ha solicitado; notificarle sobre nuevos servicios o productos que tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios en los mismos; elaborar estudios y programas que son necesarios para determinar hábitos de consumo; realizar evaluaciones periódicas de nuestros servicios a efecto de mejorar la calidad de los mismos; evaluar la calidad del servicio que brindamos, y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.
    Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales: Nombre completo Teléfono fijo y/o celular Correo electrónico Dirección y RFC.
    Es importante informarle que usted tiene derecho al Acceso, Rectificación y Cancelación de sus datos personales, a Oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin nos haya otorgado. Para ello, es necesario que envíe la solicitud en los términos que marca la Ley en su Art. 29 a Fundación Enter A.C. a través de la cuenta de correo contacto@fundacionenter.org, o bien, se comunique al teléfono (442) 2482304 ,el cual solicitamos confirme vía telefónica para garantizar su correcta recepción.
    En caso de que no desee de recibir mensajes promocionales de nuestra parte, puede enviarnos su solicitud por medio de la dirección electrónica:  <a href="mailto:contacto@fundacionenter.org">contacto@fundacionenter.org</a></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!--<button mat-button mat-dialog-close>Cancel</button>-->
  <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial class="flat">Cerrar</button>
</mat-dialog-actions>
