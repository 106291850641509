<app-subtitle [titulo]="title" [imagen]="'/assets/img/image-banner-problematica.jpg'"></app-subtitle>
<div class="feature free-text">
    <div *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">

        <div *ngIf="tipo=='Sucursal' ; else elseBlock">
            <mat-grid-list cols="4" rowHeight="2:1">
                <mat-grid-tile colspan="3" class="text-align-left">
                    <h1 class="position-bottom">{{osc?.informacion_general.nombre}}</h1>
                </mat-grid-tile>
                <mat-grid-tile>
                    <img style="width: 100%;"
                        src="{{endpoint}}{{osc?.organizacion?.logo_directorio}}"
                        alt="Logo no disponible">
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <ng-template #elseBlock>
            <mat-grid-list cols="4" rowHeight="2:1">
                <mat-grid-tile colspan="3" class="text-align-left">
                    <h1 class="position-bottom">{{osc?.informacion_general.nombre}}</h1>
                </mat-grid-tile>
                <mat-grid-tile>
                    <img style="width: 100%;" src="{{endpoint}}{{osc?.logo_directorio}}"
                        alt="Logo no disponible">
                </mat-grid-tile>
            </mat-grid-list>
        </ng-template>

        <hr>
        <mat-toolbar>
            <mat-icon>connect_without_contact</mat-icon>
            &nbsp;Contácto
        </mat-toolbar>
        <mat-list>
            <mat-list-item>
                <mat-icon mat-list-icon>public</mat-icon>
                <div mat-line>Sitio Web / Red social</div>
                <div mat-line>
                    <div *ngIf="tipo=='Sucursal' ; else elseRed">
                        <a href="{{osc?.organizacion?.network_social[0].url}}" rel="noopener" target="_blank">{{osc?.organizacion?.network_social[0].url}} </a>
                    </div>
                    <ng-template #elseRed>
                        <a href="{{osc?.network_social[0].url}}" rel="noopener" target="_blank">{{osc?.network_social[0].url}} </a>
                    </ng-template>

                </div>
            </mat-list-item>

            <mat-list-item>
                <mat-icon mat-list-icon>email</mat-icon>
                <div mat-line>Mail</div>
                <div mat-line *ngFor="let contacto of contactos">
                    <a href="mailto:{{contacto?.correo}}" rel="noopener" target="_blank">{{contacto?.correo}}</a>
                </div>
            </mat-list-item>

            <mat-list-item>
                <mat-icon mat-list-icon>location_on</mat-icon>
                <div mat-line>Dirección</div>
                <div mat-line>{{osc?.informacion_general?.direccion}}, {{osc?.informacion_general?.codigo_postal}}
                    {{osc?.municipios?.nombre}}, {{osc?.municipios?.estado_mexico?.nombre}}</div>
            </mat-list-item>

            <mat-list-item>
                <mat-icon mat-list-icon>call</mat-icon>
                <div mat-line>Teléfono</div>
                <div mat-line>
                    <a href="tel:{{osc?.informacion_general?.telefono}}" rel="noopener"
                        target="_blank">{{osc?.informacion_general?.telefono}}</a>
                </div>
            </mat-list-item>

        </mat-list>

        <mat-toolbar>
            <mat-icon>description</mat-icon>
            &nbsp;Presentación
        </mat-toolbar>
        <div [innerHTML]="replaceAll(osc?.informacion_general?.presentacion)"></div>

        <mat-toolbar>
            <mat-icon>room_service</mat-icon>
            &nbsp;Servicios
        </mat-toolbar>
        <div>
            <mat-list>
                <mat-list-item *ngFor="let item of servicios">
                    <mat-icon mat-list-icon>check</mat-icon>
                    <div mat-line>{{item}}</div>
                </mat-list-item>
            </mat-list>
        </div>

        <mat-toolbar>
            <mat-icon>bubble_chart</mat-icon>
            &nbsp;Sugerencias
        </mat-toolbar>
        <div>
            <mat-list>
                <mat-list-item *ngFor="let item of sugerencias">
                    <mat-icon mat-list-icon>check</mat-icon>
                    <div mat-line>{{item}}</div>
                </mat-list-item>
            </mat-list>
        </div>

        <div style="text-align: center;">
            <button mat-flat-button color="primary" (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
                Regresar
            </button>
        </div>
    </div>
</div>