import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { MysqlService } from 'src/app/services/mysql.service';
import { ActivatedRoute } from '@angular/router';
import { TouchSequence } from 'selenium-webdriver';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ficha-osc',
  templateUrl: './ficha-osc.component.html',
  styleUrls: ['./ficha-osc.component.scss']
})
export class FichaOscComponent implements OnInit {
  osc :any = {};
  servicios:any;
  sugerencias:any;
  isLoading = false;
  endpoint = environment.apiImagenes;
  title = "Estás a un paso";
  public contactos:any;
  public id:any;
  public tipo:any;
  constructor(private _location:Location,private _mysql:MysqlService,private activatedRoute:ActivatedRoute) { 
    setTimeout(function(){document.getElementById("btnTop").click();},0);
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.tipo= this.activatedRoute.snapshot.paramMap.get('tipo');
    this.isLoading = true;
    if(this.tipo=='Sucursal'){
      this.getSucursal();
    }else{
      this.getInstitucion();
    }

  }

  ngOnInit(): void {
   
   
  }

  getSucursal(){
    this._mysql.getSucursal(this.id).subscribe(result=>{
      if(result.size>0){
         this.osc = result.data;
         this.contactos=result.data?.contactos;
         this.servicios = result.data?.servicios[0]?.nombre.split('/');  
         this.sugerencias = result.data?.sugerencias;
       
     }else{

     }
     this.isLoading = false;
   },error=>{

    });
  }
  getInstitucion(){
    this._mysql.getOrganizaicion(this.id).subscribe(result=>{
      console.info("resultado de la busqueda",result);
      if(result.size>0){
         this.osc = result.data;
          this.contactos=result.data?.contactos;
          this.servicios = result.data?.servicios[0]?.nombre.split('/');        
          this.sugerencias = result.data?.sugerencias[0]?.nombre_sugerencias.split('/');
      }else{

      }
      this.isLoading = false;
    },error=>{
      console.info(error);
      this.isLoading = false;
    });
  }

  goBack(){
    this._location.back();
  }
  replaceAll(cadena:String){
    let re = /\n/gi;
    console.log(cadena);
    if(cadena == null){
      return ''
    }else{
      return cadena.replace(re, "<br>");
    }
    
  }

  toLowerCase(cadena:String):String{
    return cadena.toLowerCase();
  }
}
