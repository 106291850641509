import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AfiliacionComponent } from './pages/afiliacion/afiliacion.component';
import { ProcesoDeAfiliacionComponent } from './pages/afiliacion/proceso-de-afiliacion/proceso-de-afiliacion.component';
import { AvisoPrivacidadComponent } from './pages/aviso-privacidad/aviso-privacidad.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { FichaOscComponent } from './pages/ficha-osc/ficha-osc.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PreguntasFrecuentesComponent } from './pages/preguntas-frecuentes/preguntas-frecuentes.component';
import { QuienesSomosComponent } from './pages/quienes-somos/quienes-somos.component';
import { ResultadosBusquedaComponent } from './pages/resultados-busqueda/resultados-busqueda.component';
import { TerminosCondicionesComponent } from './pages/terminos-condiciones/terminos-condiciones.component';

const routes: Routes = [
  { path: 'inicio', component: HomeComponent },
  { path: 'quienes-somos', component: QuienesSomosComponent },
  { path: 'afiliacion', component: AfiliacionComponent },
  { path: 'afiliacion/alta', component: ProcesoDeAfiliacionComponent },
  { path: 'preguntas-frecuentes', component: PreguntasFrecuentesComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'terminos-y-condiciones', component: TerminosCondicionesComponent },
  { path: 'aviso-de-privacidad', component: AvisoPrivacidadComponent },
  { path: 'busqueda/:problematica/:estado/:municipio', component: ResultadosBusquedaComponent },
  { path: 'osc/ficha/:tipo/:id', component: FichaOscComponent },
  { path: '',   redirectTo: '/inicio', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
